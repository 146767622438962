import React, {Component} from 'react';
import PropTypes from 'prop-types';

/**
 * NSOneFetcher is a component that runs the nsone r.js script, creates a hidden textarea for the ns1 code to update with
 * it's output, then read that output and save it in our state.
 */
export default class NSOneFetcher extends Component {
    constructor(props) {
        super(props);
        this.onOutputUpdate = this.onOutputUpdate.bind(this);
        this.gen_unsafe_html = this.gen_unsafe_html.bind(this);

        // internal component state
        this.state = {
            isLoaded: false,
            resultText: null,
            outputNode: null,
            mo: new MutationObserver(this.onOutputUpdate)
        };
    }

    gen_unsafe_html() {
        const {resultText} = this.state;
        return {__html: resultText};
    }

    render() {
        const { isLoaded } = this.state;
        if (!isLoaded) {
            return <div />;
        }
        return <div dangerouslySetInnerHTML={this.gen_unsafe_html()} />;
    }

    componentDidMount() {
        // create a hidden textarea named "output" - this is how the nsone .js finds the DOM element to update
        const outputText = document.createElement("textarea");
        outputText.name = "output";
        outputText.id = "nsone-script-output";
        outputText.hidden = true;
        const mynode = document.body.appendChild(outputText);
        this.setState({outputNode: mynode});

        // create an async script tag with an onload callback to scriptLoaded
        const script = document.createElement("script");
        script.src = "https://info.resolver.nsone.net/r.js";
        script.async = true;
        script.onload = () => this.scriptLoaded();
        document.body.appendChild(script);
    }

    scriptLoaded() {
        // Once the nsone .js is loaded, we set our MutationOberserver to observe the hidden outputNode ...
        const { mo, outputNode } = this.state;
        mo.observe(outputNode, {subtree: true, characterData: true, attributes: true, childList: true});
    }

    onOutputUpdate(mutations) {
        // this is our MutationObserver's callback. mutations is a list of mutations (we're expecting just one)
        // each Mutation contains a list of adds and deletes, we're accessing the only add's textContent
        // and updating our resultText state, which again forces a render()
        const { mo } = this.state;
        this.setState({resultText: mutations[0].addedNodes[0].textContent, isLoaded: true});
        // the nsone js only updates once, so once we have the result, we can disconnect our MutationObserver.
        mo.disconnect();
    }
}

NSOneFetcher.defaultProps = {};

NSOneFetcher.propTypes = {
    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,
};
