import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class CopyToClipboard extends Component {
    constructor(props) {
        super(props);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.state = {
            copied: false,
        };
    }
    copyToClipboard() {
        const leftColumn = document.getElementById('headers-output-left');
        const rightColumn = document.getElementById('headers-output-right');
        // Combine the text content from both columns
        const combinedContent = `${leftColumn.innerText}\n${rightColumn.innerText}`;
        const textArea = document.createElement('textarea');
        textArea.value = combinedContent;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('Copy');
        document.body.removeChild(textArea);
        this.setState({ copied: true });
        setTimeout(() => {
            this.setState({ copied: false });
        }, 2000);
    }
    render() {
        const { copied } = this.state;
        return (
            <div>
                <button type="button" className="btn btn-primary" onClick={this.copyToClipboard}>
                    Copy to Clipboard
                </button>
                {copied && <span style={{ marginLeft: '5px', color: '#007BFF' }}>Copied!</span>}
            </div>
        );
    }
}
CopyToClipboard.defaultProps = {};
CopyToClipboard.propTypes = {
    /**
The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,
};
