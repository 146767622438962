import React, {Component} from 'react';
import PropTypes from 'prop-types';
import runTestMessageBus from "./RunTestEventBus.react";
import PopTest from "./PopTest.react";

/**
 * PopTestManager renders a table of PopTest, one per the pops prop array.
 * When button is pressed, sends a startPopTest message to the msg Bus, which the PopTest
 * components are listening for.
 */
export default class PopTestManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            istestrunning: false,
            dataSizeMb: 1,
        };
        this.sendStartPopTestMessage = this.sendStartPopTestMessage.bind(this);
        this.updateSize = this.updateSize.bind(this);
    }

    componentDidMount() {
        runTestMessageBus.on("testcomplete", (data) => {
            this.setState({istestrunning : false});
        });
    }

    sendStartPopTestMessage() {
        const { dataSizeMb, istestrunning } = this.state;
        runTestMessageBus.dispatch("startPopTest", {dataSizeMb: dataSizeMb});
        this.setState({istestrunning : true});
        // Spinning wheel should start here
    }

    updateSize() {
        const { dataSizeMb } = this.state;
        let newSizeMb;
        switch (dataSizeMb) {
            case 1:
                newSizeMb = 10;
                break;
            case 10:
                newSizeMb = 100;
                break;
            case 100:
                newSizeMb = 1;
                break;
            default:
                newSizeMb = 1;
        }
        this.setState({ dataSizeMb: newSizeMb });
    }

    renderPopTesters() {
        const rows = [];
        this.props.pops.forEach(popElem => {
            rows.push(
                <PopTest
                    key={"pop-tester-" + popElem[1]}
                    id={"pop-tester-" + popElem[1]}
                    popDisplayName={popElem[0]}
                    popTestName={popElem[1]}
                    // isVpop={false}
                />
            );
        });
        return rows;
    }

    render() {

        const { dataSizeMb} = this.state;

        const buttonClass = `btn ${this.props.sizeToggleButtonStyle} ${
        dataSizeMb === 1 ? 'btn-primary' : // Use Bootstrap info color for 1
        dataSizeMb === 10 ? 'btn-warning' : // Use Bootstrap primary color for 10
        dataSizeMb === 100 ? 'btn-success' : '' // Use Bootstrap success color for 100
        }`;

        return (
            <div id="pop-test-manager">
                <button
                    className={`${this.props.runTestButtonStyle}`}
                    onClick={this.sendStartPopTestMessage}>
                    Go!
                </button>
                <button
                    className={buttonClass}
                    onClick={this.updateSize}>
                    {this.state.dataSizeMb} MB
                </button>
                <table className={this.props.tableStyle}>
                    <thead>
                        <tr id="poptest-header">
                            <th>POP</th>
                            <th>Download IP</th>
                            <th>Download Speed</th>
                            <th>Download duration</th>
                            <th>Download RTT</th>
                            <th>Upload IP</th>
                            <th>Upload Speed</th>
                            <th>Upload Duration</th>
                            <th>Upload RTT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderPopTesters()}
                    </tbody>
                </table>
            </div>
        );
    }
}

PopTestManager.defaultProps = {
    tableStyle: "table table-primary table-striped table-bordered table-hover",
    runTestButtonStyle: "btn me-1 btn-primary",
    sizeToggleButtonStyle: "btn me-1 btn-primary",
};

PopTestManager.propTypes = {
    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    /**
     * An array of arrays [Display name, internal name] to test
     */
    pops: PropTypes.arrayOf(PropTypes.array),

    /**
     *  Run button style
     */
    runTestButtonStyle: PropTypes.string,

    /**
     * Size toggle button style
     */
    sizeToggleButtonStyle: PropTypes.string,

    /**
     * Table style
     */
    tableStyle: PropTypes.string,
};
