import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
    LineChart,
    Line,
    YAxis,
    ResponsiveContainer,
    CartesianGrid,
    ReferenceLine,
} from 'recharts';

/**
 * HeadPing - repeatedly does a curl -X HEAD on a URL, graphs response times.
 */
export default class HeadPing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pingData: [],
            pingsDone: false,
        };

        this.doPings = this.doPings.bind(this);
    }

    componentDidMount() {
        this.doPings();
    }

    async doPings() {
        const { pingCount, pingUrl } = this.props;

        // let's just give the page a sec to load
        await new Promise(res => setTimeout(res, 1000));

        for (let c = 0; c < pingCount; c++) {
            const perfStartName = "perfPingStart" + c;
            const perfEndName = "perfPingEnd" + c;

            performance.mark(perfStartName);

            fetch(pingUrl, {method: "head", cache: "no-cache"})
                .then(() => {
                    performance.mark(perfEndName);
                    const pingMeasure = performance.measure(
                        "perfPingMeasure" + c,
                        perfStartName,
                        perfEndName
                    );
                    this.setState({pingData:
                            [...this.state.pingData, {name: c.toString(), ping: pingMeasure.duration}]});
                })
                .catch(error => {
                    console.log("Ping error: " + error);
                });
            await new Promise(res => setTimeout(res, 1000));
        }
        this.setState({pingsDone: true});
    }

    asc(arr) {
        arr.sort((a, b) => a - b);
        return arr;
    }

    summ(arr) {
        return arr.reduce((a, b) => a + b, 0);
    }

    mean(arr) {
        return this.summ(arr) / arr.length;
    }

    quantile(arr, q) {
        const sorted = this.asc(arr);
        const pos = (sorted.length - 1) * q;
        const base = Math.floor(pos);
        const rest = pos - base;
        // eslint-disable-next-line no-undefined
        if (sorted[base + 1] !== undefined) {
            return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
        }
            return sorted[base];

    }


    render() {
        const { pingData, pingsDone } = this.state;
        const { lineStrokeWidth } = this.props;

        const pingTime = pingData.reduce((total, obj) => obj.ping + total, 0);
        let pingAvg = pingTime / pingData.length;
        pingAvg = Math.round(pingAvg * 100) / 100;

        let pingAvgLineHeight = pingAvg;

        const pingValues = pingData.map(a => a.ping);
        // eslint-disable-next-line no-magic-numbers
        const pingAvg95th = this.quantile(pingValues, 0.95);

        if (pingsDone === true) {
            // Drop the height of the Avg: Reference Line a bit for easier reading when done.
            // eslint-disable-next-line no-magic-numbers
            pingAvgLineHeight = pingAvgLineHeight * 0.75;
            console.log("95th quantile: " + pingAvg95th);
        }

		return (
			<div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                    <LineChart data={pingData}>
                        <Line type="monotone" dataKey="ping" stroke="#8884d8" strokeWidth={lineStrokeWidth}/>
                        <YAxis type="number" dataKey="ping" unit="ms"/>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <ReferenceLine y={pingAvgLineHeight} label={`Avg: ${pingAvg}ms`} stroke="lightblue" strokeDasharray="3 3" isFront={true}/>
                    </LineChart>
                </ResponsiveContainer>
            </div>
		)
	}
}

HeadPing.defaultProps = {
    pingCount: 20,
    lineStrokeWidth: 2,
};

HeadPing.propTypes = {
    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    /**
     * How many pings, Vasili
     */
    pingCount: PropTypes.number,

    /**
     * Width of Line in chart
     */
    lineStrokeWidth: PropTypes.number,

    /**
     * IP / URL to ping
     */
    pingUrl: PropTypes.string,
};
