import React, {Component} from 'react';
import PropTypes from 'prop-types';

/**
 * GetIps gets .. uihh .. IPs.
 */
export default class GetIps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            lookedUpHosts: {},
        };
    }

    render() {
        const { ready, lookedUpHosts } = this.state;
        if (!ready) {
            return <div/>;
        }
        const hostList = [];
        Object.entries(lookedUpHosts).map(([k, v]) => {
            hostList.push(<p key={k}><b>{k}:</b> {v}</p>)
        });

        return (
            <div>
                {hostList}
            </div>
        );
    }

    componentDidMount() {
        const { hosts } = this.props;
        const hostsObj = {};

        // launch one fetch().then() promise for each host in the list. Once all are done, setState()
        Promise.all(hosts.map(
            host => fetch("https://" + host + '/_generic/iptest')
                .then((response => {
                    hostsObj[host] = response.headers.get('x-box-test-vip');
                }))
        ))
        .then(() => {
            // all promises doneski
            this.setState({lookedUpHosts: hostsObj, ready: true});
        });
    }
}

GetIps.propTypes = {
    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    /**
     * An array of hostnames to lookup
     */
    hosts: PropTypes.arrayOf(PropTypes.string),
};
